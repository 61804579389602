/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Dropdown } from "flowbite-react";
import { Flags } from "../..";
import { useTranslation } from "react-i18next";

interface Language {
  code: string;
  name: string;
  flag: JSX.Element;
}

const availableLanguages: Language[] = [
  {
    code: "en",
    name: "English",
    flag: <Flags.UnitedKingdom />,
  },
  {
    code: "no",
    name: "Norsk",
    flag: <Flags.Norway />,
  },
];

function findActiveLanguage(
  language: string,
  availableLanguages: Language[]
): Language {
  const defaultLang = availableLanguages[0];
  return (
    availableLanguages.find((item) => item.code === language) ?? defaultLang
  );
}

export const LanguageDropdown: FC = function () {
  const { i18n } = useTranslation();
  const activeLanguage = findActiveLanguage(i18n.language, availableLanguages);

  const changeLanguageHandler = (lang: string): void => {
    i18n.changeLanguage(lang);
  };

  return (
    <Dropdown
      arrowIcon={false}
      inline
      label={
        <div className="inline-flex items-center justify-center  rounded border border-gray-300 p-2 text-darkBlue hover:bg-gray-100 hover:text-gray-900 dark:border-gray-600 dark:bg-lgb-blue-500 dark:text-white hover:dark:bg-lgb-dark-background dark-background dark:hover:text-white active:scale-95">
          <span className="sr-only">Current language</span>
          {activeLanguage.flag}
        {/* <span className="text-md">{activeLanguage.name}</span> */}
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </div>
      }
    >
      {availableLanguages.map((language) => (
        <Dropdown.Item
          key={language.code}
          onClick={() => changeLanguageHandler(language.code)}
          className="flex items-center gap-2"
        >
          {language.flag}
          <span>{language.name}</span>
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};
