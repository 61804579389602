// src/components/molecules/CustomerDetails/CustomerDetails.tsx

import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import { FaRegCopy } from "react-icons/fa";
import { SimpleList } from "../../molecules/SimpleList";
import { Product } from "../../../models";

interface ProductDetailsProps {
  product: Product | undefined;
}

export const ProductDetails: FC<ProductDetailsProps> = ({ product }) => {
  const { t } = useTranslation(["common", "products"]);
  const [hasCopied, setHasCopied] = useState(false);

  // If no product is selected, show a placeholder
  if (!product) {
    return (
      <div className="p-4 text-gray-600 dark:text-gray-300">
        {t("products:split.no_product_selected")}
      </div>
    );
  }

  return (
    <>
      {/* Product Header */}
      <div className="xl:flex justify-between items-center gap-4 pt-4 mb-4">
        <div className="flex items-center gap-4">
          <p className="text-2xl font-medium dark:text-lgb-grey-100">
            {product.descriptionShort}
          </p>

          {!hasCopied ? (
            <Tooltip content={t("common:copy")}>
              <div
                className="bg-none rounded-md relative"
                style={{ width: "34px", height: "34px" }}
              >
                <FaRegCopy
                  className="w-6 h-6 dark:text-white cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      product.descriptionShort ?? "",
                    );
                    setHasCopied(true);
                    setTimeout(() => setHasCopied(false), 2000); // Reset after 2 seconds
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex items-center text-lgb-grey-400 dark:text-lgb-grey-100">
              <HiCheck className="mr-2 h-5 w-5" />
              <p>{t("common:copied")}</p>
            </div>
          )}
        </div>
      </div>

      {/* <OrderKPIs orders={orders} loading={isQueryLoading} /> */}

      {/* Basic Info */}
      <SimpleList
  items={[
    {
      title: t("products:details.product_type"),
      value: product.productType || "-",
    },
    {
      title: t("products:details.height"),
      value: product.height ? `${product.height} cm` : "-",
    },
    {
      title: t("products:details.length"),
      value: product.length ? `${product.length} cm` : "-",
    },
    {
      title: t("products:details.width"),
      value: product.width ? `${product.width} cm` : "-",
    },
    {
      title: t("products:details.weight"),
      value: product.weight ? `${product.weight} kg` : "-",
    },
    {
      title: t("products:details.description"),
      value: product.description || "-",
    },
  ]}
/>

      {/* <RelatedOrders orders={orders} loading={isQueryLoading} /> */}
    </>
  );
};
