import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { DialogForm, SplitTable } from "../..";
import { SplitTableRef } from "../../molecules/SplitTable/SplitTable";
import { DropdownButton } from "../../atoms/Button/DropdownButton";
import {
  HiOutlineChevronDown,
  HiTrash,
  HiPencilAlt,
  HiOutlinePlus,
} from "react-icons/hi";

import { Company, Driver, Vehicle } from "../../../models";
import { useCache } from "../../../context/CacheContext";
import { Button } from "flowbite-react";
import { CompanyDetails } from "./CompanyDetails";

interface CompanyListProps {
  drivers: Driver[];
  companies: Company[];
  vehicles: Vehicle[];
  isLoading: boolean;
  isOpen: (val: boolean) => void; // toggles the right pane
  createOrUpdate: (formData: Company) => void;
}

export const CompanyList: FC<CompanyListProps> = ({
  drivers,
  companies,
  vehicles,
  isLoading,
  isOpen,
  createOrUpdate,
}) => {
  const { t } = useTranslation(["common", "driver", "company"]);
  const navigate = useNavigate();
  const { updateCacheKey } = useCache();

  const [openedCompany, setOpenedCompany] = useState<Company | undefined>();
  const [connectedDrivers, setConnectedDrivers] = useState<
    Driver[] | undefined
  >();
  const [connectedVehicles, setConnectedVehicles] = useState<
    Vehicle[] | undefined
  >();
  const [companyData, setCompanyData] = useState<Company>(Company.default());
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [filteredCompanies, setFilteredCompanies] =
    useState<Company[]>(companies);

  const splitTableRef = useRef<SplitTableRef>(null);
  const location = useLocation(); // Add location

  // Update opened driver
  const updateOpenedCompany = (company: Company | undefined) => {
    setOpenedCompany(company);
    setConnectedDrivers(drivers.filter((c) => c.companyId === company?.id));
    setConnectedVehicles(vehicles.filter((v) => v.companyId === company?.id));

    if (company) {
      isOpen(true);
      const companyName = company.name || "unknown";
      const paramSafeName = companyName.toLowerCase().replace(/\s+/g, "-");
      navigate(`/workspace/companies?company=${paramSafeName}`, {
        replace: true,
      });
      splitTableRef.current?.setSplit(true);
    } else {
      isOpen(false);
      navigate(`/workspace/companies`, { replace: true });
      splitTableRef.current?.setSplit(false);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const urlsafeName = queryParams.get("company");
    if (urlsafeName) {
      const matchingCompany = companies.find(
        (company) =>
          company.name.toLowerCase().replace(/\s+/g, "-") === urlsafeName,
      );
      if (matchingCompany) {
        updateOpenedCompany(matchingCompany);
      }
    }
  }, [location.search, companies]);

  // Filter companies based on search text
  useEffect(() => {
    if (searchText.trim().length > 0) {
      const lowerCaseSearchText = searchText.toLowerCase();
      setFilteredCompanies(
        companies.filter((company) =>
          company.name.toLowerCase().includes(lowerCaseSearchText),
        ),
      );
    } else {
      setFilteredCompanies(companies);
    }
  }, [searchText, companies]);

  // Table rows
  const tableRows = filteredCompanies.map((company) => ({
    id: company.id,
    isActive: company.id === openedCompany?.id,
    onRowClickData: company,
    cells: [
      {
        id: "name",
        children: company?.name || t("company:unknown_company"),
      },
      ...(!openedCompany
        ? [
            {
              id: "organizationNumber",
              children: company.organizationNumber || "",
            },
            {
              id: "contactEmail",
              children: company.contactEmail || "",
            },
          ]
        : []),
    ],
    actions: !openedCompany
      ? [
          {
            id: "edit",
            icon: <HiPencilAlt />,
            onActionClick: (e: React.MouseEvent) => {
              e.stopPropagation();
              createOrUpdate(company);
            },
          },
          {
            id: "delete",
            icon: <HiTrash />,
            color: "failure",
            onActionClick: (e: React.MouseEvent) => {
              e.stopPropagation();
              setCompanyData(company);
              setDeleteModalOpen(true);
            },
          },
        ]
      : [],
  }));
  

  // Handle vehicle deletion
  const handleDelete = async () => {
    try {
      await Company.delete(companyData);
      setDeleteModalOpen(false);
      updateCacheKey();

      if (openedCompany?.id === companyData.id) {
        updateOpenedCompany(undefined);
      }
    } catch (error) {
      console.error("Error deleting company:", error);
    }
  };

  const tableHeaders = !openedCompany
  ? [
      { id: "name", children: t("common:company_name") },
      { id: "organizationNumber", children: t("common:organization_number") },
      { id: "contactEmail", children: t("common:contact_email") },
    ]
  : [
      { id: "name", children: t("common:company_name") },
    ];

  

  return (
    <>
      {/* Header */}
      <div className="flex justify-between items-center mb-4 p-4">
        <div>
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            {t("company:title")}
          </h1>
          <p className="text-lgb-grey-600 text-base pt-2 pb-4 dark:text-lgb-grey-200">
            {t("company:description")}
          </p>
        </div>
        <Button
          color="light"
          onClick={() => {
            createOrUpdate(Company.default());
          }}
          className="flex items-center"
        >
          <HiOutlinePlus className="mr-2 h-5 w-5" />
          {t("company:new_company")}
        </Button>
      </div>

      {/* SplitTable */}
      <SplitTable
        ref={splitTableRef}
        searchable
        hasActions
        isLoading={isLoading}
        tableRows={tableRows}
        tableHeaders={tableHeaders}
        onRowClick={(c: Company | undefined) => updateOpenedCompany(c)}
        updateTable={(text) => setSearchText(text)}
        topRightContent={
          <div className="flex items-center">
            {openedCompany && (
              <DropdownButton
                placement="bottom-end"
                buttonText={t("company:actions.buttonText")}
                buttonAppendIcon={<HiOutlineChevronDown className="ml-2" />}
                items={[
                  {
                    text: (
                      <p className="p-4 w-48">{t("company:actions.edit")}</p>
                    ),
                    onClick: () => {
                      createOrUpdate(openedCompany);
                    },
                  },
                  {
                    text: (
                      <p className="p-4 w-48 text-lgb-red-300 dark:text-lgb-red-200 hover:text-lgb-red-200 hover:dark:text-lgb-red-300">
                        {t("company:actions.delete")}
                      </p>
                    ),
                    onClick: () => {
                      setCompanyData(openedCompany);
                      setDeleteModalOpen(true);
                    },
                  },
                ]}
              />
            )}
          </div>
        }
        content={
          openedCompany ? (
            <CompanyDetails
              company={openedCompany}
              drivers={connectedDrivers}
              vehicles={connectedVehicles}
            />
          ) : (
            <div className="p-4 text-gray-600">
              {t("company:split.no_company_selected")}
            </div>
          )
        }
      />

      {/* Delete Confirmation Dialog */}
      <DialogForm
        title={`${t("company:actions.delete_dialogTitle")} ${companyData.name ?? ""}?`}
        dismissible
        show={isDeleteModalOpen}
        toggleModal={() => setDeleteModalOpen(false)}
        buttonConfirmColor="failure"
        confirmButton={handleDelete}
        buttonConfirmText={t("common:confirm")}
        buttonCloseText={t("common:close")}
      />
    </>
  );
};
