import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import { FaRegCopy, FaExternalLinkAlt } from "react-icons/fa";
import { SimpleList } from "../../molecules/SimpleList";
import { Company, Vehicle } from "../../../models";
import { NorwayRectangle, StatensVegvesen } from "../../atoms/Icons/Flags"; 

interface VehicleDetailsProps {
  vehicle: Vehicle | undefined;
  company: Company | undefined;
}

export const VehicleDetails: FC<VehicleDetailsProps> = ({ vehicle, company }) => {
  const { t } = useTranslation(["common", "vehicles"]);
  const [hasCopied, setHasCopied] = useState(false);

  // If no vehicle is selected, show a placeholder
  if (!vehicle) {
    return (
      <div className="p-4 text-gray-600 dark:text-gray-300">
        {t("vehicles:split.no_vehicle_selected")}
      </div>
    );
  }

  // Helper function: force uppercase and add a space after the first two characters
  const formatPlateNumber = (plate: string): string => {
    const upperPlate = plate.toUpperCase();
    return upperPlate.length > 2 ? upperPlate.slice(0, 2) + " " + upperPlate.slice(2) : upperPlate;
  };

  const plateNumber =
    vehicle.registrationNumber !== ""
      ? formatPlateNumber(vehicle.registrationNumber)
      : formatPlateNumber(t("vehicles:unknown_vehicle"));

      const registrationForUrl =
      vehicle.registrationNumber !== ""
        ? vehicle.registrationNumber.replace(/\s/g, "")
        : "";
  

  return (
    <>
      {/* Vehicle Header */}
      <div className="xl:flex justify-between items-center gap-4 pt-4 mb-4">
        <div className="flex items-center bg-white dark:bg-lgb-on-dark-background border border-solid border-gray-800 rounded overflow-hidden">
          {/* Blue side panel with the Norwegian flag and "N" */}
          <div
            className="flex flex-col items-center justify-center bg-blue-700 p-1"
            style={{ width: "50px", height: "50px" }}
          >
            <NorwayRectangle />
            <span className="text-white text-xs font-bold">N</span>
          </div>

          {/* Main plate area with the registration number and copy functionality */}
          <div className="flex items-center gap-4 p-2">
            <p className="text-2xl font-medium dark:text-lgb-grey-100">{plateNumber}</p>

            {!hasCopied ? (
              <Tooltip content={t("common:copy")}>
                <div
                  className="relative rounded-md"
                  style={{ width: "34px", height: "34px" }}
                >
                  <FaRegCopy
                    className="w-6 h-6 dark:text-white cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        vehicle.name ?? t("vehicles:unknown_vehicle")
                      );
                      setHasCopied(true);
                      setTimeout(() => setHasCopied(false), 2000); // Reset after 2 seconds
                    }}
                  />
                </div>
              </Tooltip>
            ) : (
              <div className="flex items-center text-lgb-grey-400 dark:text-lgb-grey-100">
                <HiCheck className="mr-2 h-5 w-5" />
                <p>{t("common:copied")}</p>
              </div>
            )}
          </div>
          
        </div>
           {/* Button linking to the external "Kjøretøyopplysninger" URL */}
           <a
              href={`https://www.vegvesen.no/kjoretoy/kjop-og-salg/kjoretoyopplysninger/sjekk-kjoretoyopplysninger/?registreringsnummer=${registrationForUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-auto flex items-center gap-2 bg-lgb-grey-100 text-lgb-background-blue dark:text-white px-3 py-2 rounded border-solid border border-lgb-grey-200 transition transform duration-200 ease-in-out hover:shadow-lg active:scale-95">            
            <StatensVegvesen />
              <span>Kjøretøyopplysninger</span>
              <FaExternalLinkAlt className="w-4 h-4" />
            </a>
      </div>

      {/* Basic Info */}
      <SimpleList
        items={[
          {
            title: t("vehicles:details.load_capacity"),
            value: (vehicle.maxWeight || "-") + " kg",
          },
          {
            title: t("vehicles:details.vehicle_type"),
            value: vehicle.categoryCode || "-",
          },
          {
            title: t("vehicles:details.company"),
            value: company?.name,
            redirectPath: `/workspace/companies?company=${company?.name
              .toLowerCase()
              .replace(/\s+/g, "-")}`,
          },
          {
            title: t("vehicles:details.description"),
            value: vehicle.name || "-",
          },
        ]}
      />
    </>
  );
};
