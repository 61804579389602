import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { DialogForm, SplitTable } from "../..";
import { SplitTableRef } from "../../molecules/SplitTable/SplitTable";
import { DropdownButton } from "../../atoms/Button/DropdownButton";
import {
  HiOutlineChevronDown,
  HiTrash,
  HiPencilAlt,
  HiOutlinePlus,
} from "react-icons/hi";

import { Customer } from "../../../models";
import { useCache } from "../../../context/CacheContext";
import CustomerDetails from "../../molecules/CustomerDetails/CustomerDetails";
import { Button } from "flowbite-react";
import { useSearchParams } from "react-router-dom";


interface CustomersListProps {
  customers: Customer[];
  isLoading: boolean;
  isOpen: (val: boolean) => void; // toggles the right pane
  createOrUpdate: (formData: Customer) => void;
}



export const CustomersList: FC<CustomersListProps> = ({
  customers,
  isLoading,
  isOpen,
  createOrUpdate,
}) => {
  const { t } = useTranslation(["common", "customer"]);
  const navigate = useNavigate();
  const { updateCacheKey } = useCache();

  const [openedCustomer, setOpenedCustomer] = useState<Customer | undefined>();
  const [customerData, setCustomerData] = useState<Customer>(
    Customer.default(),
  );
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [filteredCustomers, setFilteredCustomers] =
    useState<Customer[]>(customers);

  const splitTableRef = useRef<SplitTableRef>(null);

  // Update opened customer
  const updateOpenedCustomer = (cust: Customer | undefined) => {
    setOpenedCustomer(cust);

    if (cust) {
      isOpen(true);
      const customerName = Customer.getCustomerName(cust) || "unknown";
      const paramSafeName = customerName.toLowerCase().replace(/\s+/g, "-");
      navigate(`/customers?customer=${paramSafeName}`, { replace: true });
      splitTableRef.current?.setSplit(true);
    } else {
      isOpen(false);
      navigate(`/customers`, { replace: true });
      splitTableRef.current?.setSplit(false);
    }
  };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Get the 'customer' query parameter
    const customerParam = searchParams.get("customer");
  
    if (customerParam && customers.length > 0) {
      // Find the matching customer by name
      const foundCustomer = customers.find(
        (cust) =>
          Customer.getCustomerName(cust)
            ?.toLowerCase()
            .replace(/\s+/g, "-") === customerParam
      );
  
      if (foundCustomer) {
        updateOpenedCustomer(foundCustomer); // Open the customer details
      }
    }
  }, [customers, searchParams]); // Runs when customers load or the URL changes
  

  // Filter customers based on search text
  useEffect(() => {
    if (searchText.trim().length > 0) {
      const lowerCaseSearchText = searchText.toLowerCase();
      setFilteredCustomers(
        customers.filter((cust) =>
          (Customer.getCustomerName(cust) || "")
            .toLowerCase()
            .includes(lowerCaseSearchText),
        ),
      );
    } else {
      setFilteredCustomers(customers);
    }
  }, [searchText, customers]);

  // Table rows
  const tableRows = filteredCustomers.map((cust) => ({
    id: cust.id,
    isActive: cust.id === openedCustomer?.id,
    onRowClickData: cust,
    cells: [
      {
        id: "displayName",
        children:
          Customer.getCustomerName(cust) || t("customer:unknown_customer"),
      },
      ...(!openedCustomer
        ? [
            {
              id: "email",
              children: cust.contactEmail || "",
            },
          ]
        : []),
    ],
    actions: !openedCustomer
      ? [
          {
            id: "edit",
            icon: <HiPencilAlt />,
            onActionClick: (e: React.MouseEvent) => {
              e.stopPropagation();
              createOrUpdate(cust);
            },
          },
          {
            id: "delete",
            icon: <HiTrash />,
            color: "failure",
            onActionClick: (e: React.MouseEvent) => {
              e.stopPropagation();
              setCustomerData(cust);
              setDeleteModalOpen(true);
            },
          },
        ]
      : [],
  }));
  

  // Handle customer deletion
  const handleDelete = async () => {
    try {
      await Customer.delete(customerData);
      setDeleteModalOpen(false);
      updateCacheKey();

      if (openedCustomer?.id === customerData.id) {
        updateOpenedCustomer(undefined);
      }
    } catch (error) {
      console.error("Error deleting customer:", error);
    }
  };

  return (
    <>
      {/* Header */}
      <div className="flex justify-between items-center mb-4 p-4">
        <div>
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            {t("customer:title")}
          </h1>
          <p className="text-lgb-grey-600 text-base pt-2 pb-4 dark:text-lgb-grey-200">
            {t("customer:description")}
          </p>
        </div>
        <Button
          color="light"
          onClick={() => {
            createOrUpdate(Customer.default());
          }}
          className="flex items-center"
        >
          <HiOutlinePlus className="mr-2 h-5 w-5" />
          {t("customer:new_customer")}
        </Button>
      </div>

      {/* SplitTable */}
      <SplitTable
        ref={splitTableRef}
        searchable
        hasActions
        isLoading={isLoading}
        tableRows={tableRows}
        tableHeaders={[
          { id: "displayName", children: t("common:customer") },
          ...(!openedCustomer ? [{ id: "email", children: t("common:email") }] : []),
        ]}
        onRowClick={(cust: Customer | undefined) => updateOpenedCustomer(cust)}
        updateTable={(text) => setSearchText(text)}
        topRightContent={
          <div className="flex items-center">
            {openedCustomer && (
              <DropdownButton
                placement="bottom-end"
                buttonText={t("location:actions.buttonText")}
                buttonAppendIcon={<HiOutlineChevronDown className="ml-2" />}
                items={[
                  {
                    text: (
                      <p className="p-4 w-48">{t("location:actions.edit")}</p>
                    ),
                    onClick: () => {
                      createOrUpdate(openedCustomer);
                    },
                  },
                  {
                    text: (
                      <p className="p-4 w-48 text-lgb-red-300 dark:text-lgb-red-200 hover:text-lgb-red-200 hover:dark:text-lgb-red-300">
                        {t("location:actions.delete.buttonText")}
                      </p>
                    ),
                    onClick: () => {
                      setCustomerData(openedCustomer);
                      setDeleteModalOpen(true);
                    },
                  },
                ]}
              />
            )}
          </div>
        }
        content={
          openedCustomer ? (
            <CustomerDetails
              customer={openedCustomer}
              edit={() => {
                createOrUpdate(openedCustomer);
              }}
              delete={() => {
                setCustomerData(openedCustomer);
                setDeleteModalOpen(true);
              }}
            />
          ) : (
            <div className="p-4 text-gray-600">
              {t("customer:split.no_customer_selected")}
            </div>
          )
        }
      />

      {/* Delete Confirmation Dialog */}
      <DialogForm
        title={`${t("customer:actions.delete_dialogTitle")} ${
          Customer.getCustomerName(customerData) ||
          t("customer:unknown_customer")
        }?`}
        dismissible
        show={isDeleteModalOpen}
        toggleModal={() => setDeleteModalOpen(false)}
        buttonConfirmColor="failure"
        confirmButton={handleDelete}
        buttonConfirmText={t("common:confirm")}
        buttonCloseText={t("common:close")}
      />
    </>
  );
};
