/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip } from "flowbite-react";
import type { FC } from "react";
import { HiAdjustments } from "react-icons/hi";
import { LanguageDropdown } from "../LanguageDropdown";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

declare global {
  interface Window {
    ProductLift?: {
      openWidget: (widgetId: string) => void;
    };
  }
}

export const BottomMenu: FC = function () {
  const { t } = useTranslation("common");

  // Map language codes to widget IDs
  const widgetMap: Record<string, string> = {
    en: "d1faf88f-d575-4e2c-a248-140855a49535", // Widget ID for English
    no: "53c1f5fb-8dc7-4089-99d0-87c22be07e31", // Widget ID for Norwegian
    // Add other language mappings if needed
  };

  const handleFeedbackClick = () => {
    const currentLanguage = i18n.language;
    const widgetId = widgetMap[currentLanguage] || widgetMap.en; // Fallback to English widget
    if (window.ProductLift) {
      window.ProductLift.openWidget(widgetId);
    }
  };

  return (
<div className="flex flex-row items-center justify-start gap-x-2 p-2 pb-6">
<LanguageDropdown />
   {/* Feedback Link */}
   <a href="#" onClick={handleFeedbackClick} className="no-underline">

<div className="p-2 border border-gray-300 rounded dark:bg-lgb-on-dark-background hover:dark:bg-lgb-dark-background dark:border-gray-600 hover:bg-gray-100 active:scale-95">
  <p className="text-sm text-gray-600 dark:text-gray-300 leading-tight">
      {t("give_feedback")}
   
  </p>
</div> </a>   
    
    </div>
  );
};
