import type { CustomFlowbiteTheme } from "flowbite-react";

export const PrimaryTheme: CustomFlowbiteTheme = {
  button: {
    base: "active:scale-95 font-normal p-1 flex justify-center items-center",
    color: {
      primary:
        "bg-lgb-primary border border-transparent hover:bg-lgb-primary-lighten text-white",
      primary700: "bg-lgb-primary-700 hover:bg-lgb-primary-400 text-white",
      primaryPurple:
        "text-white border border-transparen active:scale-95 bg-lgb-blue-300 hover:bg-lgb-blue-350 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-1 text-center inline-flex items-center me-4 dark:focus:ring-blue-800",
      red300: "bg-lgb-red-300 hover:bg-lgb-red-200 text-white",
      mainGreen: "bg-lgb-main-green hover:bg-lgb-main-green-lighten text-white",
      green400: "bg-lgb-green-400",
      green300: "bg-lgb-green-300 hover:bg-lgb-main-green-lighten text-white",
      blue400: "bg-lgb-blue-400 hover:bg-lgb-blue-200 text-white",
    },
  },
  textInput: {
    field: {
      input: {
        colors: {
          primary: "text-red",
          gray: "border-gray-300 bg-gray-50 text-gray-900 dark:bg-lgb-dark-background dark:text-white focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-700",
        },
      },
    },
  },
  textarea: {
    // The `base` classes are applied to every Textarea component.
    base: "block w-full border border-gray-300 bg-gray-50 text-gray-900 dark:bg-lgb-dark-background dark:border-gray-700 dark:text-white focus:border-cyan-500 focus:ring-cyan-500 rounded-lg p-2.5 text-sm",
    // Define color variants if needed.
    colors: {
      gray: "bg-gray-50 text-gray-900 dark:bg-lgb-dark-background dark:text-white",
      info: "bg-blue-50 text-blue-900 dark:bg-blue-900 dark:text-blue-50",
      failure: "bg-red-50 text-red-900 dark:bg-red-900 dark:text-red-50",
      warning: "bg-yellow-50 text-yellow-900 dark:bg-yellow-900 dark:text-yellow-50",
      success: "bg-green-50 text-green-900 dark:bg-green-900 dark:text-green-50",
    }
  },
  label: {
    root: {
      colors: {
        default: "text-gray-900 dark:text-white",
      },
    },
  },
  select: {
    field: {
      select: {
        colors: {
          gray: "block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50  border-gray-300 dark:border-gray-700 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 rounded-lg p-2.5 text-sm dark:bg-lgb-dark-background dark:text-white",
        },
      },
    },
  },
  sidebar: {
    root: {
      inner:
        "h-full overflow-y-auto overflow-x-hidden bg-gray-50 py-4 px-3 dark:bg-darkBlue",
    },
  },
  modal: {
    root: {
      base: "fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 dark:bg-lgb-modal-bg dark:bg-opacity-50 sm:p-6",
      show: {
        on: "flex items-center justify-center",
        off: "hidden",
      },
      sizes: {
        sm: "w-full h-full",
        md: "max-w-2xl h-auto w-auto",
        lg: "max-w-3xl h-auto w-auto",
        xl: "max-w-6xl h-auto w-auto",
      },
    },
  
    content: {
      base: "relative flex flex-col w-full sm:w-auto sm:max-h-[90vh] rounded-lg shadow-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-lgb-modal-bg overflow-hidden", // ✅ Ensures border is visible + background is applied correctly
    },
  
    body: {
      base: "dark:bg-lgb-modal-bg flex-1 overflow-auto px-6 pb-6 pt-2 bg-transparent",
    },
  
    header: {
      base: "dark:bg-lgb-modal-bg flex items-start justify-between rounded-t border-b border-gray-300 dark:border-gray-600 px-4 py-3 bg-transparent", // ✅ Keeps header transparent to not affect modal border
    },
  },
  
  card: {
    root: {
      base: "shadow-custom  border border-lgb-grey-100 dark:border-lgb-grey-600 rounded-lg p-4 bg-white dark:bg-lgb-on-dark-background",
    },
    img: {
      base: "rounded-t-lg",
    },
  },
};
