import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Svg,
  Path,
  Defs,
  ClipPath,
  Rect,
} from "@react-pdf/renderer";
import { FC } from "react";
import { Invoice } from "../../../models";
import { Timestamp } from "../../../types/Timestamp";
import { useTranslation } from "react-i18next";

interface InvoicePDFProps {
  invoice: Invoice | undefined;
}

export const InvoicePDF: FC<InvoicePDFProps> = ({ invoice }) => {
  const { t } = useTranslation(["common", "orders"]);

  if (!invoice) return null;

  const LeafSvg = () => (
    <Svg width="16" height="16" viewBox="0 0 16 16">
      <Defs>
        <ClipPath id="clip0_351_203">
          <Rect width="16" height="16" fill="white" />
        </ClipPath>
      </Defs>
      <Path
        d="M15.9724 0.224727C15.9164 0.0887387 15.7833 0 15.6364 0C1.52366 0 0 5.84874 0 8.36363C0 9.26837 0.200727 10.1135 0.597103 10.8749C0.658194 10.9927 0.77891 11.0676 0.911285 11.0706C1.03636 11.0669 1.16802 11.0044 1.23419 10.8887C2.18693 9.24219 4.88509 5.17817 9.34112 3.01892C9.52368 2.93165 9.74186 3.00655 9.82838 3.18621C9.91566 3.3673 9.84001 3.58548 9.65967 3.67275C9.32367 3.83567 9.00076 4.01312 8.68439 4.1971C8.56368 4.26764 8.44657 4.34257 8.32804 4.416C8.14985 4.52656 7.97387 4.63926 7.80222 4.75565C7.63132 4.87057 7.46257 4.98839 7.29675 5.10911C7.24511 5.14691 7.19568 5.18547 7.14474 5.22403C2.70982 8.52873 0 13.8007 0 15.6364C0 15.8371 0.162921 16 0.363648 16C0.564375 16 0.727296 15.8371 0.727296 15.6364C0.727296 15.0502 1.12657 13.8619 1.88219 12.4444C2.97893 13.3411 4.45456 13.8182 6.18185 13.8182C10.8517 13.8182 11.8299 9.37456 12.3549 6.98694C13.0618 3.77383 14.6175 1.89675 15.8931 0.620387C15.9979 0.516375 16.0284 0.360716 15.9724 0.224727Z"
        fill="#06765F"
        clipPath="url(#clip0_351_203)"
      />
    </Svg>
  );

  const styles = StyleSheet.create({
    svgContainer: {
      width: 100, // Set the desired width
      height: 100, // Set the desired height
      marginBottom: 20,
      alignSelf: "center",
    },
    page: {
      padding: 30,
      fontSize: 10,
      fontFamily: "Helvetica",
      lineHeight: 1.5,
    },
    flexContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 8,
    },
    /**
     * TOP SECTION WRAPPER
     */
    topCard: {
      flexDirection: "row",
      justifyContent: "space-between",
      gap: 8,
      marginBottom: 10,
    },
    /**
     * SENDER AND RECIEVER CONTAINER
     */
    senderAndRecieverContainer: {
      flex: 3,
      display: "flex",
      flexDirection: "column",
      gap: 8,
    },
    /**
     * SENDER
     */
    senderSection: {
      backgroundColor: "#F1E8FF", // lgb-blue-100
      borderRadius: 12,
      padding: 15,
    },

    /**
     * RECIEVER
     */
    recieverSection: {
      flex: 1,
      gap: 2,
      backgroundColor: "#F6F8FC", // lgb-grey-50
      borderRadius: 12,
      padding: 15,
    },

    /**
     * INVOICE NUMBER,
     * INVOICE DATE,
     * TOTAL SUM
     */
    invoiceInfoContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      gap: 8,
      borderRadius: 12,
      padding: 10,
      borderWidth: 1,
      borderColor: "#E4E5E9",
    },
    invoiceInfoCard: {
      backgroundColor: "#F6F8FC", // lgb-grey-50
      borderRadius: 12,
      padding: 10,
    },
    invoiceInfoTitle: {
      fontSize: 8,
      color: "#767D9D", // lgb-grey-400
    },
    invoiceInfoValue: {
      fontSize: 14,
      color: "#0E1022", // lgb-light-text
    },

    /**
     * INVOICE ITEMS
     */
    table: {
      display: "flex",
      flexDirection: "column",
      gap: 8,
      marginVertical: 10,
    },
    tableHeader: {
      flexDirection: "row",
      marginBottom: 10,
      lineHeight: 0.7,
      gap: 16,
      paddingVertical: 10,
      borderWidth: 1,
      borderColor: "#E4E5E9",
      borderRadius: 24,
      color: "#515A7A", // lgb-grey-500
    },
    footer: {
      flexDirection: "row",
      justifyContent: "space-between",
      gap: 8,
      marginTop: 20,
    },
    co2Box: {
      flex: 1,
      padding: 10,
      backgroundColor: "#E7F7F3", // lgb-green-100
      borderRadius: 12,
      fontSize: 10,
    },
    paymentBox: {
      flex: 1,
      padding: 10,
      backgroundColor: "#F1E8FF", // lgb-blue-100
      borderRadius: 12,
      fontSize: 10,
    },
  });

  const totalPrice = invoice?.items.reduce((accumulator, item) => {
    let itemTotal = item.quantity * item.price;
    if (item.discount) {
      itemTotal -= (itemTotal * item.discount) / 100;
    }
    if (item.vat) {
      itemTotal += (itemTotal * item.vat) / 100;
    }
    return Math.ceil(accumulator + itemTotal);
  }, 0);

  const renderItems = () =>
    invoice.items.map((item, index) => (
      <View
        style={{ flexDirection: "row", gap: 16, lineHeight: 0.5 }}
        key={index}
      >
        <Text
          style={{
            flex: 10,
            backgroundColor: "#F6F8FC",
            borderRadius: 24,
            fontFamily: "Helvetica-Bold",
            padding: 10,
            fontWeight: 500,
          }}
        >
          {item.itemTitle}
        </Text>
        <Text
          style={{
            flex: 1,
            textAlign: "center",
            border: 1,
            borderRadius: 24,
            borderColor: "#E4E5E9",
            paddingVertical: 10,
          }}
        >
          {item.quantity}
        </Text>
        <Text
          style={{
            flex: 2,
            textAlign: "left",
            border: 1,
            borderRadius: 24,
            borderColor: "#E4E5E9",
            padding: 10,
          }}
        >
          {item.price}
        </Text>
        <Text
          style={{
            flex: 2,
            textAlign: "left",
            backgroundColor: "#F6F8FC",
            borderRadius: 24,
            padding: 10,
            fontFamily: "Helvetica-Bold",
          }}
        >
          {item.quantity * item.price}
        </Text>
      </View>
    ));

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.flexContainer}>
          {/* Top Card */}
          <View style={styles.topCard}>
            <View style={styles.senderAndRecieverContainer}>
              {/* Logo Section */}
              <View style={styles.senderSection}>
                <Text
                  style={{
                    fontSize: 10,
                    color: "#767D9D",
                  }}
                >
                  {t("invoice:pdf.sender")}
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    paddingBottom: 5,
                    color: "#0E1022", // lgb-grey-500
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  Logibud AS
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    color: "#515A7A", // lgb-grey-500
                  }}
                >
                  Org nr. 123 456 789
                </Text>
              </View>
              {/* Customer Card */}
              <View style={styles.recieverSection}>
                <Text
                  style={{
                    fontSize: 10,
                    color: "#767D9D",
                  }}
                >
                  {t("invoice:pdf.invoiced_to")}
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    paddingBottom: 2,
                    color: "#0E1022", // lgb-grey-500
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  {invoice.customer.name}
                </Text>
                {invoice.customer.organizationNumber && (
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#515A7A", // lgb-grey-500
                    }}
                  >
                    {t("invoice:pdf.org_nr")}{" "}
                    {invoice.customer.organizationNumber}
                  </Text>
                )}
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#515A7A", // lgb-grey-500
                    }}
                  >
                    {invoice.customer.primaryAddress?.addressLine},
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#515A7A", // lgb-grey-500
                    }}
                  >
                    {" " +
                      invoice.customer.primaryAddress?.postCode +
                      " " +
                      invoice.customer.primaryAddress?.city}
                  </Text>
                </View>
              </View>
            </View>
            {/* Invoice Info Section */}
            <View style={styles.invoiceInfoContainer}>
              {/* Invoice Number */}
              <View style={styles.invoiceInfoCard}>
                <Text style={styles.invoiceInfoTitle}>
                  {t("invoice:pdf.invoice_number")}
                </Text>
                <Text style={styles.invoiceInfoValue}>{invoice.invoiceId}</Text>
              </View>
              {/* Invoice Date */}
              <View style={styles.invoiceInfoCard}>
                <Text style={styles.invoiceInfoTitle}>
                  {t("invoice:pdf.invoice_date")}
                </Text>
                <Text style={styles.invoiceInfoValue}>
                  {Timestamp.toDisplayDate(
                    invoice.createdAt || Timestamp.now(),
                  )}
                </Text>
              </View>
              {/* Total */}
              <View
                style={{ padding: 10, borderTop: 1, borderColor: "#E4E5E9" }}
              >
                <Text style={styles.invoiceInfoTitle}>
                  {t("invoice:pdf.total")}
                </Text>
                <Text
                  style={Object.assign({
                    ...styles.invoiceInfoValue,
                    fontFamily: "Helvetica-Bold",
                  })}
                >
                  {totalPrice},- NOK
                </Text>
              </View>
            </View>
          </View>

          {/* Table */}
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text
                style={{
                  flex: 10,
                  paddingHorizontal: 10,
                }}
              >
                {t("invoice:pdf.description")}
              </Text>
              <Text
                style={{
                  flex: 1,
                  textAlign: "center",
                }}
              >
                {t("invoice:pdf.quantity")}
              </Text>
              <Text
                style={{
                  flex: 2,
                  textAlign: "center",
                  paddingHorizontal: 10,
                }}
              >
                {t("invoice:pdf.price")}
              </Text>
              <Text
                style={{
                  flex: 2,
                  textAlign: "center",
                  paddingHorizontal: 10,
                }}
              >
                {t("invoice:pdf.total")}
              </Text>
            </View>
            {renderItems()}
          </View>

          {/* Total */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingVertical: 6,
              paddingHorizontal: 15,
              borderWidth: 1,
              borderColor: "#E4E5E9",
              borderRadius: 24,
              color: "#515A7A",
            }}
          >
            <Text
              style={{
                paddingTop: 4,
              }}
            >
              {t("invoice:pdf.sum_total")}
            </Text>
            <Text
              style={{
                fontSize: 16,
                color: "#7214FF",
                fontFamily: "Helvetica-Bold",
              }}
            >
              NOK {totalPrice},-
            </Text>
          </View>

          {/* Footer */}
          <View style={styles.footer}>
            <View
              style={{
                flex: 1,
                fontSize: 10,
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 8,
                  backgroundColor: "#E7F7F3", // lgb-green-100
                  borderRadius: 12,
                  padding: 10,
                }}
              >
                <View>
                  <LeafSvg />
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      color: "#767D9D",
                    }}
                  >
                    {t("invoice:pdf.co2_emissions")}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold", fontSize: 14 }}>
                    {invoice.emissions?.emissionsInKg}kg
                  </Text>
                </View>
              </View>
              <View style={{ marginTop: 25 }}>
                <Text style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}>
                  {t("invoice:pdf.thank_you_note")}
                </Text>
                <Text style={{ color: "#767D9D" }}>
                  {t("invoice:pdf.reminder").replace("{due_days}", "14")}
                </Text>
              </View>
            </View>
            <View style={styles.paymentBox}>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Helvetica-Bold",
                }}
              >
                {t("invoice:pdf.payment_info")}
              </Text>
              <Text style={{}}>
                {t("invoice:pdf.account_number")}{" "}
                {invoice.paymentInfo?.accountNumber}
              </Text>
              <Text style={{}}>
                {t("invoice:pdf.due_date")}{" "}
                {Timestamp.toDisplayDate(
                  invoice.paymentInfo?.dueDate || Timestamp.now(),
                )}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
